import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Section from "../../components/Section";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";

import styles from "./index.module.scss";
import ContactForm from "../../components/ContactForm";

const ContactPage = ({ intl }) => {
  return (
    <Layout>
      <Background>
        <Hero>
          <MotifColumn>
            <h1>{intl.formatMessage({ id: "contact.hero.title" })}</h1>
            <p>{intl.formatMessage({ id: "contact.hero.text" })}</p>
          </MotifColumn>
        </Hero>
        <Section className={styles.contactSection}>
          <ContactForm />
        </Section>
      </Background>
    </Layout>
  );
};

export default injectIntl(ContactPage);
